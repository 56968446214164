"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NifcoTotalOrderDeliveryByProductPage = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("../api");
const numberFormat = new Intl.NumberFormat();
class TotalItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th style="white-space:nowrap">${data.productName}</th>
        <td style="text-align:right;white-space:pre-wrap">${data.orderCount}</td>
        <td style="text-align:right;white-space:pre-wrap">${data.deliveryCount}</td>
        <td style="text-align:right;white-space:pre-wrap">${data.backOrderCount}</td>
        <td style="text-align:right;white-space:pre-wrap">${numberFormat.format(data.orderAmount)}</td>
        <td style="text-align:right;white-space:pre-wrap">${numberFormat.format(data.deliveryAmount)}</td>
      </tr>`;
    }
}
class NifcoTotalOrderDeliveryByProductPage extends flatout_1.Page {
    init() {
        return {
            items: new flatout_1.List(),
            totals: new flatout_1.Item(),
        };
    }
    title() {
        return 'ニフコ製品別受注集計';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ製品別受注集計</p>
        <p class="subtitle is-5"></p>
        <div class="content">
          <form class="pt-4 pb-2" onsubmit="return false">
            <div class="select is-rounded">
              <select id="filterSelect">
                <option value="currentMonth">今月以前納期分</option>
                <option value="nextMonth">来月以降納期分</option>
              </select>
            </div>
          </form>
          <table class="table">
            <thead>
              <tr>
                <th>製品番号</th>
                <th style="text-align:right">受注合計数</th>
                <th style="text-align:right">出庫合計数</th>
                <th style="text-align:right">受注残数</th>
                <th style="text-align:right">合計受注金額</th>
                <th style="text-align:right">合計出庫金額</th>
              </tr>
            </thead>
            <tbody id="items"></tbody>
            <tfoot id="totals">
              <tr>
                <th style="text-align:center">総計</th>
                <th data-id="orderCount" style="text-align:right;white-space:pre-wrap"></th>
                <th data-id="deliveryCount" style="text-align:right;white-space:pre-wrap"></th>
                <th data-id="backOrderCount" style="text-align:right;white-space:pre-wrap"></th>
                <th data-id="orderAmount" style="text-align:right;white-space:pre-wrap"></th>
                <th data-id="deliveryAmount" style="text-align:right;white-space:pre-wrap"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.items = new flatout_1.ListView(TotalItemView);
        views.totals = new flatout_1.View();
        api_1.apiClient.get('/product/total-order-delivery', { query: { target: 'currentMonth' } })
            .then(({ body }) => {
            this.data.items.reset(body.items);
            this.data.totals.update(sumTotals(body.items));
        });
    }
    handle(evts) {
        evts.filterSelect_change = (sender) => {
            api_1.apiClient.get('/product/total-order-delivery', { query: { target: sender.value } })
                .then(({ body }) => {
                this.data.items.reset(body.items);
                this.data.totals.update(sumTotals(body.items));
            });
        };
    }
}
exports.NifcoTotalOrderDeliveryByProductPage = NifcoTotalOrderDeliveryByProductPage;
function sumTotals(items) {
    let orderCount = 0, deliveryCount = 0, backOrderCount = 0, orderAmount = 0, deliveryAmount = 0;
    items.forEach(it => {
        orderCount += Number(it.orderCount);
        deliveryCount += Number(it.deliveryCount);
        backOrderCount += Number(it.backOrderCount);
        orderAmount += it.orderAmount;
        deliveryAmount += it.deliveryAmount;
    });
    return {
        orderCount,
        deliveryCount,
        backOrderCount,
        orderAmount: numberFormat.format(orderAmount),
        deliveryAmount: numberFormat.format(deliveryAmount),
    };
}
