"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NifcoOrderSortedByProductPage = exports.NifcoOrderSortedByDeadlinePage = exports.NifcoOrderSortedByOrderDatePage = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("../api");
const date_1 = require("../date");
class OptionItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `<option value="${data.value}">${data.caption}</option>`;
    }
}
class ItemSortedByOrderDateView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <td style="white-space:nowrap">${data.orderDate}</td>
        <th style="white-space:nowrap">${data.productName}</th>
        <td>${data.orderNumber}</td>
        <td style="text-align:right">${data.orderCount}</td>
        <td style="white-space:nowrap">${data.deliveryDeadline || ''}</td>
        <td>${data.shipTo || ''}</td>
        <td>${data.note || ''}</td>
      </tr>`;
    }
}
class NifcoOrderSortedByOrderDatePage extends flatout_1.Page {
    init() {
        const filterSelectOptions = [
            { caption: '全て', value: 'all' },
            { caption: '今月注文分', value: 'receiveInCurrentMonth' },
            { caption: '今月納期分', value: 'deliverInCurrentMonth' },
            { caption: '来月納期分', value: 'deliverInNextMonth' },
        ];
        return {
            orders: new flatout_1.List(),
            filterSelect: new flatout_1.List(filterSelectOptions),
        };
    }
    title() {
        return 'ニフコ受注リスト（受注日順）';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ受注リスト（受注日順）</p>
        <p class="subtitle is-5"></p>
        <div class="content">
          <form class="pt-4 pb-4" onsubmit="return false">
            <div class="select is-rounded">
              <select id="filterSelect"></select>
            </div>
            <input id="filterInput" class="input is-rounded" type="text" placeholder="受注日を部分一致でフィルタ" style="width:20em">
          </form>
          <table class="table">
            <thead>
              <tr>
                <th>受注日</th>
                <th>製品番号</th>
                <th>注文No.</th>
                <th style="width:5.5em">受注数</th>
                <th>納期</th>
                <th>納入場所</th>
                <th>備考 (伝票番号末3桁: 数)</th>
              </tr>
            </thead>
            <tbody id="orders">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.orders = new flatout_1.ListView(ItemSortedByOrderDateView);
        views.filterSelect = new flatout_1.ListView(OptionItemView);
        api_1.apiClient.get('/nifco/orders-by-date').then(({ body }) => {
            this.source = body.items;
            this.dataCache = body.items;
            this.data.orders.reset(body.items);
        });
    }
    handle(evts) {
        evts.filterInput_change = (sender) => {
            if (sender.value.length === 0) {
                this.data.orders.reset(this.dataCache);
            }
            else {
                this.data.orders.reset(this.dataCache.filter((it) => it.orderDate.indexOf(sender.value) >= 0));
            }
        };
        evts.filterSelect_change = (sender) => {
            ;
            this.findEl('filterInput').value = '';
            const [curYM, nextYM] = (0, date_1.getCurrentAndNextYearMonth)();
            switch (sender.value) {
                case 'all':
                    this.dataCache = this.source;
                    break;
                case 'receiveInCurrentMonth':
                    this.dataCache = this.source.filter((it) => curYM <= it.orderDate && it.orderDate < nextYM);
                    break;
                case 'deliverInCurrentMonth':
                    this.dataCache = this.source.filter((it) => it.deliveryDeadline < nextYM);
                    break;
                case 'deliverInNextMonth':
                    this.dataCache = this.source.filter((it) => nextYM <= it.deliveryDeadline);
                    break;
            }
            this.data.orders.reset(this.dataCache);
        };
    }
}
exports.NifcoOrderSortedByOrderDatePage = NifcoOrderSortedByOrderDatePage;
class ItemSortedByDeadlineView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <td style="white-space:nowrap">${data.deliveryDeadline || ''}</td>
        <th style="white-space:nowrap">${data.productName}</th>
        <td>${data.orderNumber}</td>
        <td style="text-align:right">${data.orderCount}</td>
        <td style="white-space:nowrap">${data.orderDate}</td>
        <td>${data.shipTo || ''}</td>
        <td>${data.note || ''}</td>
      </tr>`;
    }
}
class NifcoOrderSortedByDeadlinePage extends flatout_1.Page {
    init() {
        const filterSelectOptions = [
            { caption: '全て', value: 'all' },
            { caption: '今月納期分', value: 'deliverInCurrentMonth' },
            { caption: '来月納期分', value: 'deliverInNextMonth' },
        ];
        return {
            orders: new flatout_1.List(),
            filterSelect: new flatout_1.List(filterSelectOptions),
        };
    }
    title() {
        return 'ニフコ受注リスト（納期日順）';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ受注リスト（納期日順）</p>
        <p class="subtitle is-5"></p>
        <div class="content">
          <form class="pt-4 pb-4" onsubmit="return false">
            <div class="select is-rounded">
              <select id="filterSelect"></select>
            </div>
            <input id="filterInput" class="input is-rounded" type="text" placeholder="納期日を部分一致でフィルタ" style="width:20em">
          </form>
          <table class="table">
            <thead>
              <tr>
                <th>納期日</th>
                <th>製品番号</th>
                <th>注文No.</th>
                <th style="width:5.5em">受注数</th>
                <th>受注日</th>
                <th>納入場所</th>
                <th>備考 (伝票番号末3桁: 数)</th>
              </tr>
            </thead>
            <tbody id="orders">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.orders = new flatout_1.ListView(ItemSortedByDeadlineView);
        views.filterSelect = new flatout_1.ListView(OptionItemView);
        api_1.apiClient.get('/nifco/orders-by-deadline').then(({ body }) => {
            this.source = body.items;
            this.dataCache = body.items;
            this.data.orders.reset(body.items);
        });
    }
    handle(evts) {
        evts.filterInput_change = (sender) => {
            if (sender.value.length === 0) {
                this.data.orders.reset(this.dataCache);
            }
            else {
                this.data.orders.reset(this.dataCache.filter((it) => it.deliveryDeadline.indexOf(sender.value) >= 0));
            }
        };
        evts.filterSelect_change = (sender) => {
            ;
            this.findEl('filterInput').value = '';
            const [curYM, nextYM] = (0, date_1.getCurrentAndNextYearMonth)();
            switch (sender.value) {
                case 'all':
                    this.dataCache = this.source;
                    break;
                case 'deliverInCurrentMonth':
                    this.dataCache = this.source.filter((it) => it.deliveryDeadline < nextYM);
                    break;
                case 'deliverInNextMonth':
                    this.dataCache = this.source.filter((it) => nextYM <= it.deliveryDeadline);
                    break;
            }
            this.data.orders.reset(this.dataCache);
        };
    }
}
exports.NifcoOrderSortedByDeadlinePage = NifcoOrderSortedByDeadlinePage;
class OrderItemByProductView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th style="white-space:nowrap">${data.orderDate}</th>
        <th style="text-align:right">${data.totalOrderCount}</th>
        <td style="white-space:pre-wrap">${data.orderNumber}</td>
        <td style="white-space:pre-wrap;text-align:right">${data.orderCount}</td>
        <td style="white-space:pre-wrap">${data.deliveryDeadline}</td>
        <td style="white-space:pre-wrap">${data.shipTo}</td>
        <td style="white-space:pre-wrap">${data.note}</td>
      </tr>`;
    }
}
class ProductView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <div class="pt-4">
        <div class="columns">
          <div class="column is-one-third">製品番号 <span class="title is-4 ml-2">${data.productName}</span></div>
          <div class="column">総受注数 <span class="title is-5 ml-2">${data.orderCount}</span></div>
        </div>
        <table class="table ml-5">
          <thead>
            <tr>
              <th>受注日</th>
              <th style="width:6.5em;text-align:right">日受注数</th>
              <th>注文No.</th>
              <th style="width:5.5em;text-align:right">受注数</th>
              <th>納期</th>
              <th>納入場所</th>
              <th>備考 (伝票番号末3桁: 数)</th>
            </tr>
          </thead>
          <tbody data-id="itemsByDate">
          </tbody>
        </table>
        <hr>
      </div>`;
    }
    load(views) {
        views.itemsByDate = new flatout_1.ListView(OrderItemByProductView);
    }
}
class NifcoOrderSortedByProductPage extends flatout_1.Page {
    init() {
        return {
            products: new flatout_1.List(),
        };
    }
    title() {
        return 'ニフコ受注リスト（製品別）';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ受注リスト（製品別）</p>
        <p class="subtitle is-5"></p>
        <div class="content">
          <form class="pt-4 pb-2" onsubmit="return false">
            <input id="filterInput" class="input is-rounded" type="text" placeholder="製品番号を部分一致でフィルタ" style="width:30em">
          </form>
          <div data-id="products">
          </div>
        </div>
      </div>`;
    }
    load(views) {
        views.products = new flatout_1.ListView(ProductView);
        api_1.apiClient.get('/nifco/orders-by-product').then(({ body }) => {
            this.dataCache = body.items.map((d) => {
                const { productName, orderCount, orders } = d;
                let prevOrder = orders.shift();
                let curItem = { ...prevOrder, totalOrderCount: prevOrder.orderCount };
                const itemsByDate = [curItem];
                for (const order of orders) {
                    if (prevOrder.orderDate === order.orderDate) {
                        curItem.totalOrderCount += order.orderCount;
                        curItem.orderNumber += `\n${order.orderNumber}`;
                        curItem.orderCount += `\n${order.orderCount}`;
                        curItem.deliveryDeadline += `\n${order.deliveryDeadline}`;
                        curItem.note += `\n${order.note}`;
                        curItem.shipTo += `\n${order.shipTo}`;
                    }
                    else {
                        curItem = { ...order, totalOrderCount: order.orderCount };
                        itemsByDate.push(curItem);
                    }
                    prevOrder = order;
                }
                return { productName, orderCount, itemsByDate };
            });
            this.data.products.reset(this.dataCache);
        });
    }
    handle(evts) {
        evts.filterInput_change = (sender) => {
            if (sender.value.length === 0) {
                this.data.products.reset(this.dataCache);
            }
            else {
                this.data.products.reset(this.dataCache.filter((it) => it.productName.indexOf(sender.value) >= 0));
            }
        };
    }
}
exports.NifcoOrderSortedByProductPage = NifcoOrderSortedByProductPage;
