"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductDetailPage = exports.ProductListPage = exports.ProductItemView = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const form_1 = require("./form");
const api_1 = require("./api");
class ProductItemView extends flatout_1.View {
    html(data) {
        let srcUrl = '';
        try {
            srcUrl = window.myAppData.static.endpoint + data.image.key + data.image.sizes.thumb.key;
        }
        catch { }
        return /*html*/ `\
      <tr>
        <th><a href="./products/${data.id}">${data.code}</a></th>
        <td><img src="${srcUrl}"></td>
        <td>${data.name}</td>
        <td>${new Date(data.updatedAt * 1000).toISOString()}</td>
      </tr>`;
    }
}
exports.ProductItemView = ProductItemView;
class ProductListPage extends flatout_1.Page {
    init() {
        return {
            products: new flatout_1.List(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active"><a href="#" aria-current="page">商品一覧</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">商品</h2>
        <div class="field is-grouped">
          <p class="control">
            <a href="./products/new" class="button is-primary">新規作成</a>
            <button class="button is-primary" data-id="downloadButton">Excel ダウンロード</button>
          </p>
        </div>
        <div class="content">
          <table class="table">
            <thead>
              <tr>
                <th>商品コード</th>
                <th>画像</th>
                <th>商品名</th>
                <th>更新日時</th>
              </tr>
            </thead>
            <tbody id="products">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.products = new flatout_1.ListView(ProductItemView);
    }
    completed() {
        api_1.apiClient.get('/products').then(({ body: { items } }) => {
            this.data.products.reset(items);
        });
    }
    handle(evts) {
        evts.downloadButton_click = async () => {
            await api_1.apiClient.downloadFile('/export/products');
        };
    }
}
exports.ProductListPage = ProductListPage;
class ProductDetailPage extends flatout_1.Page {
    init() {
        return {
            form: new flatout_1.Item(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="./products/">商品一覧</a></li>
            <li class="is-active"><a href="#" aria-current="page">商品詳細</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">商品</h2>
        <div class="content">
          <form id="form">
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label">id</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-static is-small" name="id" readonly>
                  </div>
                </div>
              </div>
            </div>
            <div data-id="code"></div>
            <div data-id="name"></div>
            <div data-id="price"></div>
            <div data-id="secondPrice"></div>
            <div data-id="introduction"></div>
            <div data-id="selectOptions"></div>
            <div data-id="customizeOptions"></div>
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button data-id="saveButton" class="button is-success" type="submit">保 存</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>`;
    }
    load(views) {
        views.form = new form_1.Form({
            code: new form_1.FormTextField({
                caption: '商品コード',
                name: 'code',
                match: '^[a-zA-Z0-9_-]+$',
            }),
            name: new form_1.FormTextField({
                caption: '商品名',
                name: 'name',
                required: true,
            }),
            price: new form_1.FormTextField({
                caption: '価格',
                name: 'price',
                required: true,
                match: '^\\d+(\\.\\d{1,2})?$',
                dataType: 'number',
            }),
            secondPrice: new form_1.FormTextField({
                caption: '第2価格',
                name: 'secondPrice',
                required: false,
                match: '^\\d+(\\.\\d{1,2})?$',
                dataType: 'number',
            }),
            introduction: new form_1.FormTextAreaField({
                caption: '商品説明',
                name: 'introduction',
                required: true,
            }),
            customizeOptions: new form_1.FormOptionsField({
                caption: 'カスタマイズオプション',
                name: 'customizeOptions',
                required: false,
            }),
        }, async (data) => {
            const { selectOptions, customizeOptions, ...others } = data;
            const target = {
                ...others,
                selectOptions: this.unwrapEntriesWithModel(selectOptions.entries),
                customizeOptions: this.unwrapEntriesWithModel(customizeOptions.entries),
            };
            if (target.image) {
                const res = await api_1.apiClient.post(`/file/upload`, { body: { title: target.code } });
                const { fileId, url } = res.body;
                await fetch(url, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/octet-stream' },
                    body: target.image,
                });
                target.imageFileId = fileId;
            }
            try {
                await api_1.apiClient.post(`/products`, { body: target });
                flatout_1.App.go(`/products`);
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    completed() {
        const { productId } = this.context;
        if (productId) {
            api_1.apiClient.get(`/products/${productId}`).then(({ body: { item } }) => {
                const { selectOptions, customizeOptions, ...others } = item;
                const target = {
                    ...others,
                    selectOptions: { entries: this.wrapOptionsWithModel(selectOptions) },
                    customizeOptions: { entries: this.wrapOptionsWithModel(customizeOptions) },
                };
                this.data.form.update(target);
            });
        }
    }
    wrapOptionsWithModel(entries) {
        const rawEntries = (entries || []).map(entry => {
            const { code, name, items } = entry;
            return new flatout_1.Item({
                code,
                name,
                items: new flatout_1.List(items.map((item) => new flatout_1.Item(item))),
            });
        });
        return new flatout_1.List(rawEntries);
    }
    unwrapEntriesWithModel(entries) {
        if (entries._data.length === 0)
            return null;
        return entries._data.map((entry) => {
            const { code, name, items } = entry;
            return {
                code,
                name,
                items: items._data.map((it) => {
                    return {
                        key: it.key,
                        name: it.name,
                        price: Number(it.price) || null,
                        secondPrice: Number(it.secondPrice) || null,
                    };
                }),
            };
        });
    }
}
exports.ProductDetailPage = ProductDetailPage;
