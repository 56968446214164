"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialDetailPage = exports.MaterialListPage = exports.MaterialItemView = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const form_1 = require("./form");
const api_1 = require("./api");
class MaterialItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th><a href="./materials/${data.id}">${data.id}</a></th>
        <td>${data.materialName}</td>
        <td>${data.materialKind === 1 ? '材料' : '顔料'}</td>
        <td>${data.price}</td>
        <td>${data.outPrice}</td>
        <td>${data.clientName}</td>
        <td>${data.supplierName}</td>
        <td>${data.deleteFlag}</td>
      </tr>`;
    }
}
exports.MaterialItemView = MaterialItemView;
class MaterialListPage extends flatout_1.Page {
    init() {
        return {
            materials: new flatout_1.List(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active"><a href="#" aria-current="page">材料一覧</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">材料</h2>
        <div class="field is-grouped">
          <p class="control">
          <!--  <a href="./materials/new" class="button is-primary">新規作成</a> -->
          </p>
        </div>
        <div class="content">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>名前</th>
                <th>種別</th>
                <th>価格</th>
                <th>外注先支給価格</th>
                <th>得意先</th>
                <th>特別仕入先</th>
                <th>削除フラグ</th>
              </tr>
            </thead>
            <tbody id="materials">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.materials = new flatout_1.ListView(MaterialItemView);
    }
    completed() {
        api_1.apiClient.get('/materials').then(({ body: { items } }) => {
            this.data.materials.reset(items);
        });
    }
    handle(evts) {
        evts.downloadButton_click = async () => {
            await api_1.apiClient.downloadFile('/export/materials');
        };
    }
}
exports.MaterialListPage = MaterialListPage;
class MaterialDetailPage extends flatout_1.Page {
    init() {
        return {
            form: new flatout_1.Item(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="./materials/">材料一覧</a></li>
            <li class="is-active"><a href="#" aria-current="page">材料詳細</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">材料</h2>
        <div class="content">
          <form id="form">
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label">id</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-static is-small" name="id" readonly>
                  </div>
                </div>
              </div>
            </div>
            <div data-id="code"></div>
            <div data-id="name"></div>
            <div data-id="price"></div>
            <div data-id="introduction"></div>
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button data-id="saveButton" class="button is-success" type="submit">保 存</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>`;
    }
    load(views) {
        views.form = new form_1.Form({
            code: new form_1.FormTextField({
                caption: '材料コード',
                name: 'code',
                match: '^[a-zA-Z0-9_-]+$',
            }),
            name: new form_1.FormTextField({
                caption: '材料名',
                name: 'name',
                required: true,
            }),
            price: new form_1.FormTextField({
                caption: '価格',
                name: 'price',
                required: true,
                match: '^\\d+(\\.\\d{1,2})?$',
                dataType: 'number',
            }),
            introduction: new form_1.FormTextAreaField({
                caption: '材料説明',
                name: 'introduction',
                required: true,
            }),
        }, async (data) => {
            const target = data;
            try {
                await api_1.apiClient.post(`/materials`, { body: target });
                flatout_1.App.go(`/materials`);
            }
            catch (err) {
                console.error(err);
            }
        });
    }
    completed() {
        const { materialId } = this.context;
        if (materialId) {
            api_1.apiClient.get(`/materials/${materialId}`).then(({ body: { item } }) => {
                this.data.form.update(item);
            });
        }
    }
}
exports.MaterialDetailPage = MaterialDetailPage;
