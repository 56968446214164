"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.signOut = exports.signIn = exports.authManager = exports.apiClient = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const firebase_1 = require("./firebase");
const flatout_1 = require("@tilfin/flatout");
class APIClient extends flatout_1.HttpClient {
    async beforeRequest(path, ctx) {
        if (this.idToken) {
            ctx.headers.authorization = `bearer ${this.idToken}`;
        }
    }
    async beforeError(httpErr) {
        var _a;
        const { message } = (_a = httpErr.res) === null || _a === void 0 ? void 0 : _a.body;
        alert(message);
        return true;
    }
    changeAuth(idToken) {
        this.idToken = idToken;
    }
    clearAuth() {
        this.idToken = undefined;
    }
    async uploadFile(apiPath, body) {
        const res = await fetch(this.baseURL + apiPath, {
            method: 'POST',
            headers: {
                Authorization: `bearer ${this.idToken}`,
                'Content-Type': 'application/octet-stream',
            },
            body,
        });
        return res;
    }
    async downloadFile(apiPath, body = {}) {
        const res = await fetch(this.baseURL + apiPath, {
            method: 'POST',
            headers: {
                Authorization: `bearer ${this.idToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const ctnDp = res.headers.get('Content-Disposition');
        const md = ctnDp === null || ctnDp === void 0 ? void 0 : ctnDp.match(/filename="([^"]+)"/);
        const fileName = md ? md[1] : 'export.xlsx';
        const blobUrl = URL.createObjectURL(await res.blob());
        const anLink = document.createElement('a');
        anLink.href = blobUrl;
        anLink.download = decodeURI(fileName).replace(/\+/g, ' ');
        document.body.appendChild(anLink);
        anLink.click();
        URL.revokeObjectURL(blobUrl);
        anLink.remove();
    }
}
exports.apiClient = new APIClient({ baseURL: '/api' });
exports.authManager = new flatout_1.Core();
async function signIn() {
    const provider = new firebase_1.firebase.auth.GoogleAuthProvider();
    await firebase_1.firebase.auth().setPersistence(firebase_1.firebase.auth.Auth.Persistence.SESSION);
    try {
        const result = await firebase_1.firebase.auth().signInWithPopup(provider);
        const { user, credential } = result;
        const { idToken } = credential;
        window.myAppData.idToken = idToken;
        return { isLoggedIn: true, user };
    }
    catch (error) {
        // The email of the user's account used.
        //var email = error.email
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential
        console.error(error.message);
        alert(error.message);
        return { isLoggedIn: false };
    }
}
exports.signIn = signIn;
async function signOut() {
    await firebase_1.firebase.auth().signOut();
    exports.authManager.say('authStateChanged', { user: null });
}
exports.signOut = signOut;
