"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormOptionsField = exports.FormFileField = exports.FormTextAreaField = exports.FormTextField = exports.FormSelectField = exports.FormMultipleSelectField = exports.Form = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const flatout_1 = require("@tilfin/flatout");
class Form extends flatout_1.FormView {
    constructor(fieldMap, onSubmit) {
        super();
        this.fieldMap = fieldMap;
        this.onSubmit = onSubmit;
        this.fields = [];
    }
    load(views) {
        for (const [name, field] of Object.entries(this.fieldMap)) {
            views[name] = field;
            this.fields.push(field);
        }
    }
    handle(evts) {
        evts.submit = async () => {
            let allValid = true;
            this.fields.forEach(it => {
                if (!it.validate())
                    allValid = false;
            });
            if (!allValid)
                return;
            const saveButton = this.findEl('saveButton');
            saveButton.classList.add('is-loading');
            await this.onSubmit(this.data);
            saveButton.classList.remove('is-loading');
        };
    }
}
exports.Form = Form;
class OptionView extends flatout_1.View {
    html(data) {
        return `<option value="${data.code}">${data.name}</option>`;
    }
}
class FormMultipleSelectField extends flatout_1.View {
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="select is-multiple">
              <select data-id="selectInput" multiple size="3"></select>
            </div>
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    set data(value) {
        const selectEl = this.views.selectInput.el;
        for (const optEl of Array.from(selectEl.options)) {
            optEl.selected = value.includes(optEl.value);
        }
    }
    get data() {
        const selectEl = this.views.selectInput.el;
        return Array.from(selectEl.selectedOptions).map(it => asType(it.value, this.dataType));
    }
    validate() {
        const selectEl = this.views.selectInput.el;
        selectEl.dispatchEvent(new Event('input'));
        return !selectEl.classList.contains('is-danger');
    }
    load(views) {
        views.selectInput = new flatout_1.ListView(OptionView);
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.selectInput_input = (sender) => {
            const selectEl = sender;
            const val = Array.from(selectEl.selectedOptions).map(it => it.value);
            if (this.required) {
                if (val.length === 0) {
                    sender.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'この項目は選択必須です。';
                    return;
                }
            }
            sender.classList.remove('is-danger');
            this.helpEl.classList.remove('is-danger');
            this.helpEl.textContent = '';
        };
    }
    completed() {
        this.views.selectInput.data = this.options;
    }
}
exports.FormMultipleSelectField = FormMultipleSelectField;
class FormSelectField extends flatout_1.View {
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="select">
              <select name="${this.name}" data-id="selectInput"></select>
            </div>
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    set data(value) {
        const selectEl = this.views.selectInput.el;
        selectEl.value = value;
    }
    get data() {
        const selectEl = this.views.selectInput.el;
        return asType(selectEl.value, this.dataType);
    }
    validate() {
        const selectEl = this.views.selectInput.el;
        selectEl.dispatchEvent(new Event('input'));
        return !selectEl.classList.contains('is-danger');
    }
    load(views) {
        views.selectInput = new flatout_1.ListView(OptionView);
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.selectInput_input = (sender) => {
            const selectEl = sender;
            if (this.required) {
                if (!selectEl.value) {
                    sender.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'この項目は選択必須です。';
                    return;
                }
            }
            sender.classList.remove('is-danger');
            this.helpEl.classList.remove('is-danger');
            this.helpEl.textContent = '';
        };
    }
    completed() {
        this.views.selectInput.data = this.options;
    }
}
exports.FormSelectField = FormSelectField;
class FormTextField extends flatout_1.View {
    constructor(props) {
        super(props);
    }
    init() {
        if (this.match) {
            this.matchMessage = `${this.match} にしてください。`;
            this.matchRegExp = new RegExp(this.match);
        }
        return {};
    }
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input data-id="textInput" class="input" name="${this.name}"
              type="${this.type || 'text'}"
              placeholder="${this.placeholder || ''}">
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    get data() {
        return asType(this.inputEl.value, this.dataType);
    }
    validate() {
        this.inputEl.dispatchEvent(new Event('input'));
        return !this.inputEl.classList.contains('is-danger');
    }
    load() {
        this.inputEl = this.findEl('textInput');
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.textInput_input = (sender) => {
            if (this.required) {
                if (!sender.value.length) {
                    sender.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'この項目は必須です。';
                    return;
                }
            }
            if (sender.value && this.matchRegExp) {
                if (!this.matchRegExp.test(sender.value)) {
                    sender.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = this.matchMessage;
                    return;
                }
            }
            sender.classList.remove('is-danger');
            this.helpEl.classList.remove('is-danger');
            this.helpEl.textContent = '';
        };
    }
}
exports.FormTextField = FormTextField;
class FormTextAreaField extends flatout_1.View {
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <textarea data-id="textInput" class="textarea" name="${this.name}" placeholder="${this.placeholder || ''}"></textarea>
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    get data() {
        return this.inputEl.value;
    }
    validate() {
        this.inputEl.dispatchEvent(new Event('input'));
        return !this.inputEl.classList.contains('is-danger');
    }
    load() {
        this.inputEl = this.findEl('textInput');
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.textInput_input = (sender) => {
            if (this.required) {
                if (!sender.value.length) {
                    sender.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'この項目は必須です。';
                    return;
                }
            }
            sender.classList.remove('is-danger');
            this.helpEl.classList.remove('is-danger');
            this.helpEl.textContent = '';
        };
    }
}
exports.FormTextAreaField = FormTextAreaField;
class FormFileField extends flatout_1.View {
    constructor(props) {
        super(props);
    }
    get data() {
        return this._data;
    }
    set data(value) {
        if (value && value.key) {
            this._data = null;
        }
    }
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <div class="file has-name">
              <label class="file-label">
                <input data-id="file" class="file-input" type="file" name="file">
                <span class="file-cta">
                  <span class="file-label">ファイルを選択</span>
                </span>
                <span data-id="fileName" class="file-name"></span>
              </label>
            </div>
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    validate() {
        this.inputEl.dispatchEvent(new Event('input'));
        return !this.inputEl.classList.contains('is-danger');
    }
    load() {
        this.inputEl = this.findEl('file');
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.file_change = (sender) => {
            if (this.required) {
                if (!this.inputEl.value.length) {
                    this.inputEl.classList.add('is-danger');
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'この項目は必須です。';
                    return;
                }
            }
            this.inputEl.classList.remove('is-danger');
            this.helpEl.classList.remove('is-danger');
            this.helpEl.textContent = '';
            this.findEl('fileName').textContent = '';
            this._data = null;
            const input = sender;
            const files = input.files || [];
            if (files.length) {
                if ( /*files[0].type.endsWith('text/plain')*/true) {
                    const file = files[0];
                    const reader = new FileReader();
                    reader.onload = (re) => {
                        this.findEl('fileName').textContent = file.name;
                        this._data = file;
                    };
                    reader.readAsDataURL(file);
                }
                else {
                    this.helpEl.classList.add('is-danger');
                    this.helpEl.textContent = 'ファイルではありません。';
                }
            }
        };
    }
}
exports.FormFileField = FormFileField;
function asType(value, dataType) {
    if (dataType === 'number') {
        return value.length ? Number(value) : null;
    }
    if (dataType === 'boolean') {
        return value === '1' || value === 'true';
    }
    return value;
}
class FormOptionsEntryItemView extends flatout_1.View {
    html() {
        return /*html*/ `
      <tr>
        <td><input data-id="key" class="input" type="text" placeholder="key"></td>
        <td><input data-id="name" class="input" type="text" placeholder="name"></td>
        <td><input data-id="price" class="input" type="text" dataType="number" placeholder="price"></td>
        <td><input data-id="secondPrice" class="input" type="text" dataType="number" placeholder="secondPrice"></td>
        <td style="vertical-align: middle"><a data-id="deleteButton" class="delete"></a></td>
      </tr>
    `;
    }
    handle(evts) {
        evts.deleteButton_click = () => {
            this.fire('deleteOptionItem', { item: this.data });
        };
        evts.key_input = (sender) => {
            this.data.update({ key: sender.value });
        };
        evts.name_input = (sender) => {
            this.data.update({ name: sender.value });
        };
        evts.price_input = (sender) => {
            this.data.update({ price: Number(sender.value) || null });
        };
        evts.secondPrice_input = (sender) => {
            this.data.update({ secondPrice: Number(sender.value) || null });
        };
    }
}
class FormOptionsEntryView extends flatout_1.View {
    html() {
        return /*html*/ `
      <div class="box">
        <div class="columns">
          <div class="column">
            <div class="field is-grouped">
              <div class="field-label is-normal">
                <label class="label">コード</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input data-id="code" class="input" type="text" placeholder="code">
                  </div>
                </div>
              </div>
              <div class="field-label is-normal">
                <label class="label">名前</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input data-id="name" class="input" type="text" placeholder="name">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="control" style="text-align: right">
              <button data-id="deleteButton" class="button is-danger is-outlined">オプション削除</button>
            </div>
          </div>
        </div>
        <div>
          <header>
            <label class="label">選択肢</label>
          </header>
          <table class="table">
            <thead>
              <th>key</th>
              <th>名称</th>
              <th>価格</th>
              <th>第2価格</th>
              <th></th>
            </thead>
            <tbody data-id="items">
            </tbody>
          </table>
          <footer class="control">
            <button data-id="addItemButton" class="button is-primary is-outlined">選択肢追加</button>
          </footer>
        <div>
      </div>
    `;
    }
    load(views) {
        views.items = new flatout_1.ListView(FormOptionsEntryItemView);
    }
    handle(evts) {
        evts.deleteOptionItem = (sender, e) => {
            this.data.items.remove(e.detail.item);
        };
        evts.addItemButton_click = () => {
            this.data.items.add(new flatout_1.Item({ key: '', code: '', price: null, secondPrice: null }));
        };
        evts.deleteButton_click = () => {
            this.fire('deleteEntry', { item: this.data });
        };
        evts.code_input = (sender) => {
            this.data.update({ code: sender.value });
        };
        evts.name_input = (sender) => {
            this.data.update({ name: sender.value });
        };
    }
}
class FormOptionsField extends flatout_1.View {
    init() {
        return new flatout_1.Item({ entries: new flatout_1.List() });
    }
    html() {
        return /*html*/ `
    <div class="field is-horizontal">
      <div class="field-label">
        <label class="label" for="${this.name}">${this.caption}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div data-id="entries"></div>
          <div style="margin: 1.5rem 0">
            <button class="button is-primary" data-id="addEntryButton">オプション追加</button>
          </div>
          <p data-id="help" class="help"></p>
        </div>
      </div>
    </div>`;
    }
    validate() {
        //const selectEl = this.views.selectInput.el as HTMLSelectElement
        //selectEl.dispatchEvent(new Event('input'))
        return true;
    }
    load(views) {
        views.entries = new flatout_1.ListView(FormOptionsEntryView);
        this.helpEl = this.findEl('help');
    }
    handle(evts) {
        evts.deleteEntry = (sender, e) => {
            this.data.entries.remove(e.detail.item);
        };
        evts.addEntryButton_click = () => {
            this.data.entries.add(new flatout_1.Item({
                code: '',
                name: '',
                items: new flatout_1.List([new flatout_1.Item({ key: '', code: '', price: null, secondPrice: null })]),
            }));
        };
    }
}
exports.FormOptionsField = FormOptionsField;
