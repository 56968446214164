"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerDetailPage = exports.CustomerListPage = exports.CustomerItemView = void 0;
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("./api");
const form_1 = require("./form");
class CustomerItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th><a href="./customers/${data.id}">${data.id}</a></th>
        <td>${data.customer_name}</td>
        <td>${data.isClient ? '✔' : ''}</td>
        <td>${data.isSubcontractor ? '✔' : ''}</td>
        <td>${data.isSupplier ? '✔' : ''}</td>
        <td>${data.isMoldmaker ? '✔' : ''}</td>
      </tr>`;
    }
}
exports.CustomerItemView = CustomerItemView;
class CustomerListPage extends flatout_1.Page {
    init() {
        return {
            customers: new flatout_1.List(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active"><a href="#" aria-current="page">得意先一覧</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">得意先</h2>
        <div class="content">
          <form class="form">
            <div class="field has-addons">
              <div class="control">
                <input data-id="idInput" class="input" type="text" placeholder="ID" value="" style="width: 400px">
              </div>
              <div class="control">
                <button data-id="searchIdButton" class="button is-info">ID 検索</button>
              </div>
            </div>
          </form>
        </div>
        <div class="content">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-primary" data-id="downloadButton">Excel ダウンロード</button>
            </p>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>取引先名</th>
                <th>得意先</th>
                <th>外注先</th>
                <th>仕入先</th>
                <th>金型先</th>
              </tr>
            </thead>
            <tbody id="customers">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.customers = new flatout_1.ListView(CustomerItemView);
        api_1.apiClient.get('/customers').then(({ body }) => {
            this.data.customers.reset(body.items);
        });
    }
    handle(evts) {
        evts.searchIdButton_click = async () => {
            const id = this.findEl('idInput').value;
            api_1.apiClient
                .get(`/customers/${id}`, {})
                .then(({ body: Client }) => {
                this.data.customers.reset([Client]);
            })
                .catch(err => {
                var _a, _b, _c;
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            });
        };
    }
}
exports.CustomerListPage = CustomerListPage;
class CustomerDetailPage extends flatout_1.Page {
    init() {
        return {
            form: new flatout_1.Item(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="./customers/">得意先一覧</a></li>
            <li class="is-active"><a href="#" aria-current="page">得意先詳細</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">カスタマー</h2>
        <p class="subtitle is-5">Client</p>
        <div class="content">
          <form id="form">
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label">ID</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-static" name="id" readonly>
                  </div>
                </div>
              </div>
            </div>
            <div data-id="code"></div>
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button data-id="saveButton" class="button is-success" type="submit">保 存</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr>
          <p class="control">
            <button data-id="couponsButton" class="button is-primary">クーポン一覧</button>
          </p>
        </div>
      </div>`;
    }
    load(views) {
        views.form = new form_1.Form({
            code: new form_1.FormTextField({
                caption: 'コード',
                name: 'code',
                match: '^[a-zA-Z0-9_-|]+$',
            }),
        }, async (data) => {
            var _a, _b, _c;
            try {
                const body = data;
                await api_1.apiClient.post(`/customers`, { body });
                flatout_1.App.go(`/customers/${body.id}`);
            }
            catch (err) {
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            }
        });
    }
    handle(evts) {
        evts.couponsButton_click = () => {
            const { id } = this.data.form;
            flatout_1.App.go(`/customers/${id}/coupons`, {});
        };
    }
    completed() {
        const { clientId } = this.context;
        if (clientId) {
            api_1.apiClient.get(`/customers/${clientId}`).then(({ body }) => {
                this.data.form.update(body);
            });
        }
    }
}
exports.CustomerDetailPage = CustomerDetailPage;
