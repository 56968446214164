"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkerDetailPage = exports.WorkerListPage = exports.WorkerItemView = void 0;
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("./api");
const form_1 = require("./form");
class WorkerItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th><a href="./workers/${data.id}">${data.id}</a></th>
        <td>${data.workerName}</td>
      </tr>`;
    }
}
exports.WorkerItemView = WorkerItemView;
class WorkerListPage extends flatout_1.Page {
    init() {
        return {
            workers: new flatout_1.List(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active"><a href="#" aria-current="page">内職一覧</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">内職</h2>
        <div class="content">
          <table class="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>氏名</th>
              </tr>
            </thead>
            <tbody id="workers">
            </tbody>
          </table>
        </div>
      </div>`;
    }
    load(views) {
        views.workers = new flatout_1.ListView(WorkerItemView);
        api_1.apiClient.get('/workers').then(({ body }) => {
            this.data.workers.reset(body.items);
        });
    }
    handle(evts) {
        evts.searchIdButton_click = async () => {
            const id = this.findEl('idInput').value;
            api_1.apiClient
                .get(`/workers/${id}`, {})
                .then(({ body: Client }) => {
                this.data.workers.reset([Client]);
            })
                .catch(err => {
                var _a, _b, _c;
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            });
        };
    }
}
exports.WorkerListPage = WorkerListPage;
class WorkerDetailPage extends flatout_1.Page {
    init() {
        return {
            form: new flatout_1.Item(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="./workers/">内職一覧</a></li>
            <li class="is-active"><a href="#" aria-current="page">内職詳細</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">内職</h2>
        <div class="content">
          <form id="form">
            <div class="field is-horizontal">
              <div class="field-label is-small">
                <label class="label">id</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input class="input is-static" name="id" readonly>
                  </div>
                </div>
              </div>
            </div>
            <div data-id="code"></div>
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button data-id="saveButton" class="button is-success" type="submit">保 存</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>`;
    }
    load(views) {
        views.form = new form_1.Form({
            code: new form_1.FormTextField({
                caption: 'コード',
                name: 'code',
                match: '^[a-zA-Z0-9_-|]+$',
            }),
        }, async (data) => {
            var _a, _b, _c;
            try {
                const body = data;
                await api_1.apiClient.post(`/workers`, { body });
                flatout_1.App.go(`/workers/${body.id}`);
            }
            catch (err) {
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            }
        });
    }
    handle(evts) {
        evts.couponsButton_click = () => {
            const { id } = this.data.form;
            flatout_1.App.go(`/workers/${id}/coupons`, {});
        };
    }
    completed() {
        const { clientId } = this.context;
        if (clientId) {
            api_1.apiClient.get(`/workers/${clientId}`).then(({ body }) => {
                this.data.form.update(body);
            });
        }
    }
}
exports.WorkerDetailPage = WorkerDetailPage;
