"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NifcoDeliInstPage = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const form_1 = require("../form");
const api_1 = require("../api");
class OrderItemView extends flatout_1.View {
    init(defaults) {
        const { save } = defaults;
        defaults.canSave = save > 0;
        defaults.save = save === 2;
        return defaults;
    }
    html(data) {
        return /*html*/ `\
      <tr>
        <td class="selectCol">${data.canSave ? (`<input data-id="selectCheckBox" type="checkbox" style="transform:scale(1.5)"` + (data.save ? 'checked' : '') + `>`) : ''}</td>
        <td style="white-space:nowrap">${data.orderDate}</td>
        <td style="white-space:nowrap">${data.productName}</td>
        <td>${data.orderNumber}</td>
        <td style="text-align:right">${data.orderCount}</td>
        <td style="white-space:nowrap">${data.deliveryDeadline}</td>
        <td>${data.shipTo}</td>
        <td>${data.note}</td>
        <td>${data.message}</td>
      </tr>`;
    }
    handle(evts) {
        evts.selectCheckBox_change = (sender) => {
            this.data.update({ save: sender.checked });
        };
    }
}
class NifcoDeliInstPage extends flatout_1.Page {
    init() {
        return {
            form: new flatout_1.Item(),
            orders: new flatout_1.List(),
        };
    }
    title() {
        return 'ニフコ納入指示から注文登録';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ納入指示</p>
        <p class="subtitle is-5">extファイルから注文を登録</p>
        <form id="form" class="block">
            <div data-id="file"></div>
            <div class="field is-horizontal">
              <div class="field-label"></div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <button data-id="saveButton" class="button is-success" type="submit">ロードして内容確認</button>
                  </div>
                </div>
              </div>
          </div>
        </form>
        <div class="content">
          <table class="table">
            <thead>
              <tr>
                <th class="selectCol">対象</th>
                <th>受注日 (データ作成日)</th>
                <th>製品番号</th>
                <th>注文No. (発注番号)</th>
                <th style="width:5.5em">注文数 (受注数)</th>
                <th>納期</th>
                <th>納入場所</th>
                <th>備考 (伝票番号末3桁: 数)</th>
                <th>登録状況の説明</th>
              </tr>
            </thead>
            <tbody id="orders">
            </tbody>
          </table>
        </div>
        <form class="block">
          <div class="field">
            <p class="help">登録、更新する項目の先頭のチェックボックスをチェックしてください。</p>
          </div>
          <div class="field">
            <button data-id="registerButton" class="button is-light is-danger" disabled>選択項目を登録</button>
          </div>
        </form>
      </div>`;
    }
    load(views) {
        views.form = new form_1.Form({
            file: new form_1.FormFileField({ caption: 'extファイル', name: 'file' }),
        }, async (data) => {
            var _a, _b, _c;
            if (!data.file)
                return;
            try {
                const res = await api_1.apiClient.post(`/import/nifco/orders`, { body: data, headers: { 'Content-Type': 'multipart/form-data' } });
                this.data.orders.reset(new flatout_1.List(res.body.items, { wrapItem: flatout_1.Item }));
                if (res.body.items.length) {
                    this.findEl('registerButton').disabled = false;
                }
            }
            catch (err) {
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            }
        });
        views.orders = new flatout_1.ListView(OrderItemView);
    }
    completed() {
    }
    handle(evts) {
        evts.registerButton_click = async () => {
            var _a, _b, _c;
            try {
                const items = [];
                this.data.orders.forEach((order) => {
                    const { save, ...item } = order;
                    if (save) {
                        items.push(item);
                    }
                });
                if (items.length === 0) {
                    alert('登録可能な項目がありません。');
                    return;
                }
                const res = await api_1.apiClient.post(`/save/nifco/orders`, { body: { items } });
                this.data.orders.reset(res.body.items);
            }
            catch (err) {
                alert((_c = (_b = (_a = err.res) === null || _a === void 0 ? void 0 : _a.body) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : err.message);
            }
        };
    }
}
exports.NifcoDeliInstPage = NifcoDeliInstPage;
