"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firebase_1 = require("./firebase");
const flatout_1 = require("@tilfin/flatout");
const home_1 = require("./home");
const customer_1 = require("./customer");
const api_1 = require("./api");
const order_1 = require("./order");
const product_1 = require("./product");
const worker_1 = require("./worker");
const deli_inst_1 = require("./nifco/deli-inst");
const orders_1 = require("./nifco/orders");
const shipping_1 = require("./nifco/shipping");
const material_1 = require("./material");
const aggregate_1 = require("./nifco/aggregate");
const delivery_1 = require("./delivery");
window.myAppData = window.myAppData || {};
function initApp() {
    flatout_1.App.activate(home_1.Root, {
        index: home_1.HomePage,
        products: {
            index: product_1.ProductListPage,
            new: product_1.ProductDetailPage,
            ':productId': {
                index: product_1.ProductDetailPage,
            },
        },
        customers: {
            index: customer_1.CustomerListPage,
            new: customer_1.CustomerDetailPage,
            ':customerId': {
                index: customer_1.CustomerDetailPage,
                orders: {
                    index: order_1.OrderListPage,
                    new: order_1.OrderDetailPage,
                    ':orderId': {
                        index: order_1.OrderDetailPage,
                    },
                },
            },
        },
        deliveries: {
            'by-product': delivery_1.DeliveriesByProductPage,
        },
        materials: {
            index: material_1.MaterialListPage,
        },
        nifco: {
            'deli-inst': deli_inst_1.NifcoDeliInstPage,
            'orders-by-date': orders_1.NifcoOrderSortedByOrderDatePage,
            'orders-by-deadline': orders_1.NifcoOrderSortedByDeadlinePage,
            'orders-by-product': orders_1.NifcoOrderSortedByProductPage,
            'shipping': shipping_1.NifcoShippingPage,
            'total-order-delivery-by-product': aggregate_1.NifcoTotalOrderDeliveryByProductPage,
        },
        workers: {
            index: worker_1.WorkerListPage,
        }
    }, { mode: 'HISTORY' });
    window.initApp = undefined;
}
window.initApp = initApp;
const firebaseConfig = {
    apiKey: "AIzaSyBYK6JODyywoNLndr97UFRXJft8fjvWVSI",
    authDomain: "fujikasei-kg.firebaseapp.com",
    projectId: "fujikasei-kg",
    storageBucket: "fujikasei-kg.appspot.com",
    messagingSenderId: "1084958631217",
    appId: "1:1084958631217:web:0c7d49b9fc07aa0fc07c10"
};
firebase_1.firebase.initializeApp(firebaseConfig);
firebase_1.firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        user
            .getIdToken(true)
            .then((idToken) => {
            api_1.apiClient.changeAuth(idToken);
        })
            .catch((_err) => {
            api_1.apiClient.clearAuth();
            window.location.replace('./');
        })
            .then(() => {
            if (window.initApp)
                window.initApp();
            api_1.authManager.say('authStateChanged', { user });
        });
    }
    else {
        api_1.apiClient.clearAuth();
        if (window.initApp)
            window.initApp();
        flatout_1.App.go('/');
        api_1.authManager.say('authStateChanged', { user });
    }
});
