"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomePage = exports.Root = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("./api");
class LoginPanelView extends flatout_1.View {
    handle(evts) {
        evts.signInButton_click = async (sender) => {
            const input = sender;
            input.disabled = true;
            await (0, api_1.signIn)().catch(err => {
                console.error(err);
            });
            input.disabled = false;
        };
        evts.signOutButton_click = () => {
            (0, api_1.signOut)().catch(err => {
                console.error(err);
            });
        };
    }
    setField(name, value) {
        if (super.update(name, value, null))
            return;
        if (name === 'loginState') {
            if (!value) {
                this.findEl('loginModal').classList.add('is-active');
            }
            else {
                this.findEl('loginModal').classList.remove('is-active');
            }
        }
    }
}
class Root extends flatout_1.View {
    constructor() {
        super({ rootEl: 'root', contentEl: 'main' });
    }
    html() {
        return /* html */ `
    <div class="columns">
      <div id="leftPane" class="column is-narrow">
        <aside class="menu mt-2 ml-2">
          <ul class="menu-list">
            <li>
              <a>材料</a>
              <ul>
                <li><a href="./materials/">材料一覧</a></li>
              </ul>
            </li>
            <li>
              <a>ニフコ</a>
              <ul>
                <li><a href="./nifco/deli-inst">納入指示</a></li>
                <li><a href="./nifco/orders-by-date">受注日順受注リスト</a></li>
                <li><a href="./nifco/orders-by-deadline">納期日順受注リスト</a></li>
                <li><a href="./nifco/orders-by-product">製品別受注リスト</a></li>
                <li><a href="./nifco/shipping">出荷報告</a></li>
                <li><a href="./nifco/total-order-delivery-by-product">製品別受注集計</a></li>
                <li><a href="./deliveries/by-product">出庫検収</a></li>
              </ul>
            </li>
          </ul>
        </aside>
      </div>
      <main id="main" class="column" style="margin:15px 0"></main>
    </div>`;
    }
    load(views) {
        views.loginPanel = new LoginPanelView();
    }
    handle() {
        api_1.authManager.listened('authStateChanged', (ctx) => {
            const { user = null } = ctx;
            const loginPanel = this.views.loginPanel;
            loginPanel.setField('loginState', user != null);
            loginPanel.setField('userName', user ? user.displayName : '');
        });
    }
    title(s) {
        const topTitle = 'fjkmm フジ化成工業';
        return s ? `${s} | ${topTitle}` : topTitle;
    }
}
exports.Root = Root;
class HomePage extends flatout_1.Page {
    title() {
        return '';
    }
    html() {
        return `\
    <div class="container is-fluid">
      <div class="content">
        <p></p>
      </div>
    </div>`;
    }
}
exports.HomePage = HomePage;
