"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveriesByProductPage = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("./api");
const date_1 = require("./date");
const numberFormat = new Intl.NumberFormat();
function roundPrice(value) {
    return Math.round(value * 100) / 100;
}
class DeliveryItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <td style="white-space:pre-wrap">${data.productName}</td>
        <td style="white-space:pre-wrap">${data.orderNumber}</td>
        <td style="white-space:pre-wrap;text-align:center">${data.deliveryDate}</td>
        <td style="white-space:pre-wrap;text-align:right">${numberFormat.format(data.deliveryCount)}</td>
        <td style="white-space:pre-wrap;text-align:right">${roundPrice(data.price)}</td>
        <td style="white-space:pre-wrap;text-align:right">${numberFormat.format(Math.round(data.deliveryAmount))}</td>
      </tr>`;
    }
}
class ProductView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <div class="pb-3">
        <table class="table">
          <thead>
            <tr>
              <th>製品番号</th>
              <th>注文No.</th>
              <th style="text-align:center">納入日</th>
              <th style="text-align:right">数量</th>
              <th style="text-align:right">最終単価</th>
              <th style="text-align:right">納入金額</th>
            </tr>
          </thead>
          <tbody data-id="deliveries">
          </tbody>
          <tfoot>
            <tr>
              <th style="text-align:left">小計</th>
              <th></th>
              <th></th>
              <td style="text-align:right">${numberFormat.format(data.totalCount)}</td>
              <td style="text-align:right"></td>
              <td style="text-align:right">${numberFormat.format(Math.round(data.totalAmount))}</td>
            </tr>
          </tfoot>
        </table>
      </div>`;
    }
    load(views) {
        views.deliveries = new flatout_1.ListView(DeliveryItemView);
    }
}
class DeliveriesByProductPage extends flatout_1.Page {
    init() {
        return {
            items: new flatout_1.List(),
            totals: new flatout_1.Item(),
        };
    }
    title() {
        return 'ニフコ出庫検収明細';
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <p class="title is-3">ニフコ出庫検収明細 <span data-id="yearMonth"></span></p>
        <div class="content">
          <form class="pt-4 pb-4" onsubmit="return false">
            <div class="select is-rounded">
              <select id="filterSelect">
                <option value="currentMonth">今月分</option>
                <option value="previousMonth" selected>先月分</option>
              </select>
            </div>
          </form>
          <div id="items">
          </div>
          <div id="totals" class="level">
            <p class="level-item"><strong>合計数量</strong> <span data-id="deliveryCount" style="margin-left:1.3em"></span></p>
            <p class="level-item"><strong>合計金額</strong> <span data-id="deliveryAmount" style="margin-left:1.3em"></span></p>
          </div>
        </div>
      </div>`;
    }
    load(views) {
        views.items = new flatout_1.ListView(ProductView);
        views.totals = new flatout_1.View();
        const [, prevYM] = (0, date_1.getCurrentAndPrevYearMonth)();
        api_1.apiClient.get('/deliveries/by-product', { query: { ym: prevYM } })
            .then(({ body }) => {
            this.data.items.reset(body.items);
            this.data.totals.update(sumTotals(body.items));
        });
        this.findEl('yearMonth').textContent = prevYM.split('-').join('年') + '月';
    }
    handle(evts) {
        evts.filterSelect_change = (sender) => {
            const [curYM, prevYM] = (0, date_1.getCurrentAndPrevYearMonth)();
            switch (sender.value) {
                case 'currentMonth':
                    api_1.apiClient.get('/deliveries/by-product', { query: { ym: curYM } })
                        .then(({ body }) => {
                        this.data.items.reset(body.items);
                        this.data.totals.update(sumTotals(body.items));
                    });
                    this.findEl('yearMonth').textContent = curYM.split('-').join('年') + '月';
                    break;
                case 'previousMonth':
                    api_1.apiClient.get('/deliveries/by-product', { query: { ym: prevYM } })
                        .then(({ body }) => {
                        this.data.items.reset(body.items);
                        this.data.totals.update(sumTotals(body.items));
                    });
                    this.findEl('yearMonth').textContent = prevYM.split('-').join('年') + '月';
                    break;
            }
        };
    }
}
exports.DeliveriesByProductPage = DeliveriesByProductPage;
function sumTotals(items) {
    let count = 0, amount = 0;
    items.forEach(it => {
        count += Number(it.totalCount);
        amount += Math.round(it.totalAmount);
    });
    return {
        deliveryCount: numberFormat.format(count),
        deliveryAmount: numberFormat.format(Math.floor(amount)),
    };
}
