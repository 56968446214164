"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDetailPage = exports.OrderListPage = exports.OrderItemView = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
const flatout_1 = require("@tilfin/flatout");
const api_1 = require("./api");
const ORDER_STATUS_LABELS = {
    Placed: '-',
    Authorized: 'オーソリ済',
    AwaitingAcceptance: '受付待ち',
    AwaitingPayment: '支払待ち',
    PaymentCancelled: '支払キャンセル',
    Denied: '拒否',
    Accepted: '受付',
    Ready: '準備完了',
    Delivered: '配送済み',
    Captured: '代金受領済み',
    Refunded: '返金済み',
};
class OrderItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `\
      <tr>
        <th><a href="./shops/${data.shopCode}/orders/${data.id}">${data.code}</a></th>
        <th>${data.windowTicketCode}</th>
        <td>${data.orderer.code}</td>
        <td>${ORDER_STATUS_LABELS[data.status]}</td>
        <td>${new Date(data.createdAt * 1000).toISOString()}</td>
        <td>${new Date(data.updatedAt * 1000).toISOString()}</td>
      </tr>`;
    }
}
exports.OrderItemView = OrderItemView;
class OrderListPage extends flatout_1.Page {
    init() {
        return {
            orders: new flatout_1.List(),
        };
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active"><a href="#" aria-current="page">注文一覧</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">注文</h2>
        <div class="field is-grouped">
          <p class="control">
            <!--<a href="./orders/new" class="button is-primary">新規作成</a>-->
          </p>
        </div>
        <div class="content">
          <table class="table">
          <thead>
            <tr>
              <th>コード</th>
              <th>チケットコード</th>
              <th>発注者</th>
              <th>ステータス</th>
              <th>作成日時</th>
              <th>更新日時</th>
            </tr>
          </thead>
          <tbody data-id="orders">
          </tbody>
        </table>
        </div>
      </div>`;
    }
    load(views) {
        views.orders = new flatout_1.ListView(OrderItemView);
    }
    completed() {
        const { shopCode } = this.context;
        const date = window.myAppData.today;
        api_1.apiClient.get(`/orders?shopCode=${shopCode}&date=${date}`).then(({ body }) => {
            this.data.orders.reset(body.items.map((it) => Object.assign(it, { shopCode })));
        });
    }
}
exports.OrderListPage = OrderListPage;
class OrderDetailItemView extends flatout_1.View {
    html(data) {
        return /*html*/ `
      <tr>
        <th>${data.sortOrder}</th>
        <td>${data.productName}</td>
        <td>${data.quantity}</td>
        <td>${data.amount}</td>
        <td>${data.taxRate}%</td>
      </tr>`;
    }
}
class OrderDetailPage extends flatout_1.Page {
    init() {
        return new flatout_1.Item();
    }
    html() {
        return /*html*/ `\
      <div class="container is-fluid">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a href="./orders/">注文一覧</a></li>
            <li class="is-active"><a href="#" aria-current="page">注文詳細</a></li>
          </ul>
        </nav>
        <h2 class="title is-3">注文</h2>
        <table class="table">
          <tr>
            <th>注文コード</th>
            <td data-id="code"></td>
          </tr>
          <tr>
            <th>チケットコード</th>
            <td data-id="windowTicketCode"></td>
          </tr>
          <tr>
            <th>ステータス</th>
            <td data-id="status"></td>
          </tr>
          <tr>
            <th>合計金額</th>
            <td data-id="totalAmount"></td>
          </tr>
          <tr>
            <th>合計税額</th>
            <td data-id="taxAmount"></td>
          </tr>
        </table>
        <h3 class="title is-4">明細</h3>
        <table class="table">
          <thead>
            <tr>
              <th>通し番号</th>
              <th>商品名</th>
              <th>数量</th>
              <th>金額</th>
              <th>税率</th>
            </tr>
          </thead>
          <tbody data-id="details">
          </tbody>
        </table>
        <div class="buttons">
          <button data-id="denyButton" class="button is-danger">拒否</button>
          <button data-id="acceptButton" class="button is-success">受付</button>
          <button data-id="readyButton" class="button is-primary">準備完了</button>
          <button data-id="deliverButton" class="button is-primary">配達完了</button>
          <button data-id="captureButton" class="button is-primary">代金受領</button>
        </div>
      </div>`;
    }
    load(views) {
        views.form = new flatout_1.FormView();
        views.details = new flatout_1.ListView(OrderDetailItemView);
    }
    handle(evts) {
        evts.denyButton_click = async () => {
            try {
                const { body } = await api_1.apiClient.post(`/orders/${this.data.id}/deny`, {
                    body: {},
                });
                this.data.update({ status: ORDER_STATUS_LABELS[body.order.status] });
            }
            catch ({ res }) {
                const res_ = res;
                alert(res_.body.message);
            }
        };
        evts.acceptButton_click = async () => {
            try {
                const { body } = await api_1.apiClient.post(`/orders/${this.data.id}/accept`, {
                    body: {},
                });
                this.data.update({ status: ORDER_STATUS_LABELS[body.order.status] });
            }
            catch ({ res }) {
                const res_ = res;
                alert(res_.body.message);
            }
        };
        evts.readyButton_click = async () => {
            try {
                const { body } = await api_1.apiClient.post(`/orders/${this.data.id}/ready`, {
                    body: {},
                });
                this.data.update({ status: ORDER_STATUS_LABELS[body.order.status] });
            }
            catch ({ res }) {
                const res_ = res;
                alert(res_.body.message);
            }
        };
        evts.deliverButton_click = async () => {
            try {
                const { body } = await api_1.apiClient.post(`/orders/${this.data.id}/deliver`, {
                    body: {},
                });
                this.data.update({ status: ORDER_STATUS_LABELS[body.order.status] });
            }
            catch ({ res }) {
                const res_ = res;
                alert(res_.body.message);
            }
        };
        evts.captureButton_click = async () => {
            try {
                const { body } = await api_1.apiClient.post(`/orders/${this.data.id}/capture`, {
                    body: {},
                });
                this.data.update({ status: ORDER_STATUS_LABELS[body.order.status] });
            }
            catch ({ res }) {
                const res_ = res;
                alert(res_.body.message);
            }
        };
    }
    completed() {
        const { orderId } = this.context;
        if (orderId) {
            api_1.apiClient.get(`/orders/${orderId}`).then(({ body: order }) => {
                order.status = ORDER_STATUS_LABELS[order.status];
                this.data.update(order);
            });
        }
    }
}
exports.OrderDetailPage = OrderDetailPage;
