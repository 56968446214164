"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentAndPrevYearMonth = exports.getCurrentAndNextYearMonth = void 0;
function getCurrentAndNextYearMonth() {
    const now = new Date();
    now.setHours(now.getHours() + 9);
    const cur = now.toISOString().substring(0, 7);
    now.setMonth(now.getMonth() + 1);
    const next = now.toISOString().substring(0, 7);
    return [cur, next];
}
exports.getCurrentAndNextYearMonth = getCurrentAndNextYearMonth;
function getCurrentAndPrevYearMonth() {
    const now = new Date();
    now.setHours(now.getHours() + 9);
    const cur = now.toISOString().substring(0, 7);
    now.setMonth(now.getMonth() - 1);
    const prev = now.toISOString().substring(0, 7);
    return [cur, prev];
}
exports.getCurrentAndPrevYearMonth = getCurrentAndPrevYearMonth;
